/*---------------- Variable ----------------*/
$vert: #91c023;
$vertFonce: #8dae51;
$bleu: #0f4c81;
$rouge: #df3207;
$gris: #f3f3f4;
$grisOnglet: #959798;
$bodyColor: #353535;

/*---------------- CSS sideBar ----------------*/
$sidebar-bg-color: $bleu !default;
$sidebar-color: #fff !default;
$highlight-color: $vert !default;
$submenu-bg-color: $bodyColor !default;
$sidebar-width: 210px !default;
@import '~react-pro-sidebar/dist/scss/styles.scss';

.pro-sidebar .pro-menu a.selected {
    color: $vert;
    text-decoration: underline;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item::before {
    content: none;
}
.containerSidBar {
    position: fixed;
    height: 100%;
    display: flex;
}

.menuItem {
    padding: 19px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sidebarContent {
    padding: 24px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media (max-width: 800px) {
    .containerSidBar {
        z-index: 1001;
    }
}
/*---------------- CSS Material UI ----------------*/

.MuiTabs-root {
    background-color: $grisOnglet;
}
.MuiDialog-paperWidthSm {
    width: 100%;
}
.MuiChip-deleteIcon {
    position: absolute;
    right: 28px;
}
.MuiTab-wrapper {
    font-weight: 600;
}
.MuiMenu-list.MuiList-padding {
    padding: 10px;
}
.MuiPickersModal-dialogRoot {
    width: auto;
}
.MuiTab-textColorPrimary {
    color: #fff !important;
}
.MuiTab-textColorPrimary.Mui-selected {
    color: $bleu !important;
}
.MuiButton-outlinedPrimary {
    color: $bleu !important;
    border: 1px solid $bleu !important;
}
.fc-scrollgrid-sync-inner {
    display: block !important;
    position: inherit;
}
.MuiDrawer-paperAnchorRight {
    width: 40% !important;
}
.fc-direction-ltr {
    height: 100%;
}
@media (max-width: 576px) {
    .MuiBox-root {
        padding: 0px !important;
    }
    .MuiMenu-list.MuiList-padding {
        width: 80% !important;
    }
    .MuiMenuItem-root {
        min-height: 0 !important;
        line-height: 1 !important;
    }
    .MuiPopover-paper {
        max-width: 70% !important;
    }
    .jWpogG {
        margin: 10px 0px 0px 0px !important;
    }
}

/*---------------- CSS DateRange RsuiteJS----------------*/
.rs-picker-menu {
    z-index: 2000;
}

/*---------------- CSS Balise HTML ----------------*/
body {
    height: 100vh;
    color: $bodyColor;
}
#root {
    height: 100%;
    background-color: $gris;
    flex-grow: 1;
}
tbody tr th div {
    display: none;
}

.listeContainer {
    margin: 0px 25px 0 25px;
    background-color: #f3f3f4;
}
.listeAtelier {
    margin: 0px 0px 0px 0px;
}
.Styles_containerBar__bntEN {
    background-color: $bleu !important;
}
.Styles_containerBar__33aTj {
    background-color: $bleu !important;
}
.MuiTab-root {
    font-size: 12px !important;
}
.fc .fc-toolbar.fc-header-toolbar {
    font-size: 0.6em !important;
    margin-left: 5% !important;
}
a {
    text-decoration-line: none !important;
}
@media (max-width: 576px) {
    thead {
        display: block;
        position: absolute;
        top: -9999px;
        left: -9999px;
        border-bottom: 2px solid #333;
    }
    thead tr {
        display: block;
        position: absolute;
        top: -9999px;
        left: -9999px;
        border-bottom: 2px solid #333;
    }
    tbody {
        display: block;
    }
    tbody tr {
        border: 1px solid #000;
        padding: 0.25em;
        margin: 0.25em;
    }
    tbody tr th {
        display: block;
        border: none !important;
        position: relative;
        padding-left: calc(50% + 10px) !important;
        text-align: left !important;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }
    tbody tr th div {
        position: absolute;
        display: block;
        left: 1rem;
        width: calc(50% - 20px);
        white-space: pre-wrap;
        overflow-wrap: break-word;
        text-align: left !important;
        font-weight: 600;
    }
    th {
        display: block;
    }
    .fc tbody {
        display: contents !important;
    }
    .fc .fc-scrollgrid-liquid {
        height: 300%;
    }
}
/*---------------- CSS ClassName ----------------*/
.w5 {
    width: 5%;
}
.w7 {
    width: 7%;
}
.w10 {
    width: 10%;
}
.w15 {
    width: 15%;
}
.w20 {
    width: 20%;
}
.w25 {
    width: 25%;
}
.w30 {
    width: 30%;
}
.w33 {
    width: 33%;
}
.w35 {
    width: 35%;
}
.w40 {
    width: 40%;
}
.w45 {
    width: 45%;
}
.w50 {
    width: 50%;
}
.w55 {
    width: 55%;
}
.w60 {
    width: 60%;
}
.w65 {
    width: 65%;
}
.w70 {
    width: 70%;
}
.w75 {
    width: 75%;
}
.w80 {
    width: 80%;
}
.w85 {
    width: 85%;
}
.w90 {
    width: 90%;
}
.w100 {
    width: 100%;
}
.w2_3em {
    width: 2.3em;
}
.maxW37 {
    max-width: 37%;
}
.h5 {
    height: 5%;
}
.h10 {
    height: 10%;
}
.h20 {
    height: 20%;
}
.h60 {
    height: 60%;
}
.h70 {
    height: 70%;
}
.h80 {
    height: 80%;
}
.h85 {
    height: 85%;
}
.h90 {
    height: 90%;
}
.h100 {
    height: 100%;
}
.h30px {
    height: 30px;
}
.hmax60 {
    max-height: 60%;
}
.hmax70 {
    max-height: 70%;
}
.hmax80 {
    max-height: 80%;
}
.nomargin {
    margin: 0;
}
.m1 {
    margin: 1%;
}
.m2 {
    margin: 2%;
}
.m5 {
    margin: 5%;
}
.mt0 {
    margin-top: 0;
}
.mt0_5 {
    margin-top: 0.5%;
}
.mt0_5em {
    margin-top: 0.5em;
}
.mt1 {
    margin-top: 1%;
}
.mt2 {
    margin-top: 2%;
}
.mt5 {
    margin-top: 5%;
}
.mt9 {
    margin-top: 9%;
}
.mt10 {
    margin-top: 10%;
}
.mt15 {
    margin-top: 15%;
}
.mt20 {
    margin-top: 20%;
}
.mt16px {
    margin-top: 16px;
}
.mt10px {
    margin-top: 10px;
}
.mt16px {
    margin-top: 16px;
}
.mt47px {
    margin-top: 47px;
}
.mt0_5em {
    margin-top: 0.5em;
}
.mt7em {
    margin-top: 7em;
}
.mt-2em {
    margin-top: -2em;
}
.mb0 {
    margin-bottom: 0;
}
.mb0_5 {
    margin-bottom: 0.5%;
}
.mb1 {
    margin-bottom: 1%;
}
.mb2 {
    margin-bottom: 2%;
}
.mb3 {
    margin-bottom: 3%;
}
.mb4 {
    margin-bottom: 4%;
}
.mb5 {
    margin-bottom: 5%;
}
.mb10 {
    margin-bottom: 10%;
}
.mb20 {
    margin-bottom: 20%;
}
.mb10px {
    margin-bottom: 10px;
}
.ml1 {
    margin-left: 1%;
}
.ml2 {
    margin-left: 2%;
}
.ml3 {
    margin-left: 3%;
}
.ml4 {
    margin-left: 4%;
}
.ml5 {
    margin-left: 5%;
}
.ml7 {
    margin-left: 7%;
}
.ml8 {
    margin-left: 8%;
}
.ml10 {
    margin-left: 10%;
}
.ml13 {
    margin-left: 13%;
}
.ml15 {
    margin-left: 15%;
}
.ml20 {
    margin-left: 20%;
}
.ml25 {
    margin-left: 25%;
}
.ml35 {
    margin-left: 25%;
}
.ml40 {
    margin-left: 40%;
}
.ml53 {
    margin-left: 53%;
}
.ml10em {
    margin-left: 10em;
}
.ml0_3em {
    margin-left: 0.3em;
}
.ml2em {
    margin-left: 2em;
}
.ml30px {
    margin-left: 30px;
}
.ml210px {
    margin-left: 210px;
}
.ml9 {
    margin-left: 9%;
}
.ml7 {
    margin-left: 7%;
}
.ml40 {
    margin-left: 40%;
}
.ml50 {
    margin-left: 50%;
}
.mr0_5 {
    margin-right: 0.5%;
}
.mr1 {
    margin-right: 1%;
}
.mr2 {
    margin-right: 2%;
}
.mr5 {
    margin-right: 5%;
}
.mr6 {
    margin-right: 6%;
}
.mr7 {
    margin-right: 7%;
}
.mr10 {
    margin-right: 10%;
}
.mr20 {
    margin-right: 20%;
}
.mr40 {
    margin-right: 40%;
}
.mr10px {
    margin-right: 10px;
}
.p0_3 {
    padding: 0.3%;
}

.p0_5 {
    padding: 0.5%;
}
.p1 {
    padding: 1%;
}
.p1_5 {
    padding: 1.5%;
}
.p2 {
    padding: 2%;
}
.p3 {
    padding: 3%;
}
.p5 {
    padding: 5%;
}
.pt1 {
    padding-top: 0.3%;
}
.p20 {
    padding: 20%;
}
.p10_35 {
    padding: 10% 35%;
}
.pr0_4rem {
    padding-right: 0.4rem;
}
.pl0_4rem {
    padding-left: 0.4rem;
}
.pl1 {
    padding-left: 1%;
}
.pl2 {
    padding-left: 2%;
}
.pl4 {
    padding-left: 4%;
}
.pl5 {
    padding-left: 5%;
}
.pl10 {
    padding-left: 10%;
}
.p10_20 {
    padding: 10% 20% 10% 20%;
}
.pr1 {
    padding-right: 1%;
}
.pr2 {
    padding-right: 2%;
}
.pr5 {
    padding-right: 5%;
}
.pb10 {
    padding-bottom: 10%;
}
.pt65px {
    padding-top: 65px;
}
.pBadge {
    padding: 3px 7px;
}
.noPadding {
    padding: 0;
}
.overflowAuto {
    overflow: auto;
}
.nowrap {
    white-space: nowrap;
}
.textRight {
    text-align: right;
}
.positionAbsolute {
    position: absolute;
}
.positionRelative {
    position: relative;
}
.top18 {
    top: 18%;
}
.top-5 {
    top: -5%;
}
.top77 {
    top: 77%;
}
.top76 {
    top: 76%;
}
.top60 {
    top: 60%;
}
.left40 {
    left: 40%;
}
.left43 {
    left: 43%;
}
.left35 {
    left: 35%;
}
.textLeft {
    text-align: left;
}
.textCenter {
    text-align: center;
}
.textEnd {
    text-align: end;
}
.cursorPointer {
    cursor: pointer;
}
.alignICenter {
    align-items: center;
}
.alignIStart {
    align-items: start;
}
.alignIEnd {
    align-items: end;
}
.justifyContantCenter {
    justify-content: center;
}
.justifySpaceEvenly {
    justify-content: space-evenly;
}
.justifyContantEnd {
    justify-content: end;
}
.inlineBlock {
    display: inline-block;
}
.none {
    display: none;
}
.block {
    display: block;
}
.grid {
    display: grid;
}
.flex {
    display: flex;
}
.contents {
    display: contents;
}
.index1 {
    z-index: 1;
}
.index10 {
    z-index: 10;
}

.grid3Column {
    grid-template-columns: 33% 33% 33%;
}
.grid4Column {
    grid-template-columns: 25% 25% 25% 25%;
}
.grid6Column {
    grid-template-columns: 17% 17% 17% 17% 15% 17%;
}
.flexEnd {
    justify-content: flex-end;
}
.spaceBetween {
    justify-content: space-between;
}
.spaceAround {
    justify-content: space-around;
}
.center {
    justify-content: center;
}
.flexJCSpaceBetween {
    justify-content: space-between;
}
.selfCenter {
    justify-self: center;
}
.flexDRow {
    flex-direction: row;
}
.directionColumn {
    flex-direction: column;
}
.flex1-2 {
    flex: 0.5;
}
.flewWrap {
    flex-wrap: wrap;
}
.floatRight {
    float: right;
}
.floatLeft {
    float: left;
}
.fixed {
    position: fixed;
}
.verticalTop {
    vertical-align: top;
}
.fontSize10 {
    font-size: 10px;
}
.fontSize14 {
    font-size: 14;
}
.fontSize20 {
    font-size: 20px;
}
.fontSize17 {
    font-size: 17px;
}
.fontSize25 {
    font-size: 25px;
}
.fontSize50 {
    font-size: 50px;
}
.fontSize0_6em {
    font-size: 0.6em;
}
.fontSize0_7em {
    font-size: 0.7em;
}
.fontSize0_8em {
    font-size: 0.8em;
}
.fontSize0_9em {
    font-size: 0.9em;
}
.fontSize1_1em {
    font-size: 1.2em;
}
.fontSize2_5em {
    font-size: 2.5em;
}
.fontWeight600 {
    font-weight: 600;
}
.lineHeight1_5 {
    line-height: 1.5;
}
.fontBold {
    font-weight: bold;
}
.leading100 {
    line-height: 100%;
}
.radius4 {
    border-radius: 4px;
}
.radius10 {
    border-radius: 10px;
}
.radius30 {
    border-radius: 30px;
}
.radius40 {
    border-radius: 40px;
}
.radius50 {
    border-radius: 150px;
}
.border1darkgrey {
    border: 1px solid darkgrey;
}
.border10 {
    border: 10px solid #aeb8cf;
}
.border2 {
    border: 2px solid #aeb8cf;
}
.border3 {
    border: 3px solid #aeb8cf;
}
.border4 {
    border: 1px solid #000;
}
.bgcVertOpacite0_7 {
    background-color: rgba(145, 192, 35, 0.7);
}
.bgcfff {
    background-color: #fff;
}
.bgcfffOpacity0_3 {
    background: rgba(255, 255, 255, 0.3);
}
.bgcdarkgreyOpacity0_3 {
    background-color: rgba(112, 128, 144, 0.5);
}
.bgcSilverOpacity0_3 {
    background-color: rgba(192, 192, 192, 0.5);
}
.bgclightgreyOpacity0_3 {
    background-color: rgba(211, 211, 211, 0.5);
}
.bgcgainsboroOpacity0_3 {
    background-color: rgba(220, 220, 220, 0.5);
}
.bgc000 {
    background-color: #000;
}
.bgcf3f3f4 {
    background-color: #f3f3f4;
}
.bgcBleu {
    background-color: #2e76b0;
}
.bgcBleu688bb7 {
    background-color: #2c70c6;
}
.bgcBleu0f4c81 {
    background-color: #0f4c81;
}
.bgcBleu00BFFF {
    background-color: #00bfff;
}
.bgcRougeFF6347 {
    background-color: #ff6347;
}
.bgcVert32CD32 {
    background-color: #32cd32;
}
.bgcViolet884DA7 {
    background-color: #884da7;
}
.bgcBadgeAtelier {
    background-color: #23c6c8;
}
.bgcBadgeAnneeCycle {
    background-color: #1ab394;
}
.bgcBadgeCountEleve {
    background-color: #d1dade;
}
.bgcBadgeGroupe {
    background-color: #82b56e;
}
.bgcGrisClair {
    background-color: #dcdcdc;
}
.bgcGris {
    background-color: #999999;
}
.bgcfce5cd {
    background-color: #fce5cd;
}
.cGris {
    color: darkgrey;
}
.cWhite {
    color: #ffffff;
}
.cGray {
    color: #999fa5;
}
.cBleu2c70c6 {
    color: #2c70c6;
}
.cRed {
    color: red;
}
.cVert32CD32 {
    color: #32cd32;
}
.opacity0_7 {
    opacity: 0.4;
}

/* ---------responsive--------- */

@media (max-width: 576px) {
    .xs_w100 {
        width: 100%;
    }
    .xs_w90 {
        width: 90%;
    }
    .xs_w50 {
        width: 50%;
    }
    .xs_w33 {
        width: 33%;
    }
    .xs_w30 {
        width: 30%;
    }
    .xs_w20 {
        width: 20%;
    }
    .xs_w0 {
        width: 0%;
    }
    .xs_h80 {
        height: 80%;
    }
    .xs_none {
        display: none;
    }
    .xs_block {
        display: block;
    }
    .xs_flexColumn {
        flex-direction: column;
    }
    .xs_nomargin {
        margin: 0;
    }
    .xs_mt5 {
        margin-top: 5%;
    }
    .xs_mt10 {
        margin-top: 10%;
    }
    .xs_mb5 {
        margin-bottom: 5%;
    }
    .xs_mb2 {
        margin-bottom: 2%;
    }
    .xs_mr2 {
        margin-right: 2%;
    }
    .xs_mr5 {
        margin-right: 5%;
    }
    .xs_mr1 {
        margin-right: 1%;
    }
    .xs_ml0 {
        margin-left: 0%;
    }
    .xs_ml1 {
        margin-left: 1%;
    }
    .xs_ml2 {
        margin-left: 2%;
    }
    .xs_ml5 {
        margin-left: 5%;
    }
    .xs_flex {
        display: flex !important;
    }
    .xs_alignIBaseline {
        align-items: baseline;
    }
    .xs_inlineBlock {
        display: inline-block;
    }
    .xs_directionColumn {
        flex-direction: column;
    }
    .xs_flexDRow {
        flex-direction: row;
    }
    .xs_directionColumnReverse {
        flex-direction: column-reverse;
    }
    .xs_flexCenter {
        justify-content: center;
    }
    .xs_floatRight {
        float: right;
    }
    .xs_textCenter {
        text-align: center;
    }
    .xs_textLeft {
        text-align: left;
    }
    .xs_block {
        display: block;
    }
    .react-tel-input .form-control {
        width: 200px !important;
    }
    .xs_fontSize20px {
        font-size: 20px;
    }
    .xs_fontSize0_6em {
        font-size: 0.6em;
    }
    .xs_fontSize0_7em {
        font-size: 0.7em;
    }
    .xs_fontSize0_8em {
        font-size: 0.8em;
    }
    .xs_fontSize0_9em {
        font-size: 0.9em;
    }
}
/*---------------- CSS ClassName Personalisé----------------*/

.waitStyle {
    position: fixed;
    background-color: rgba(217, 238, 255, 0.4);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.fontLittleSmallGrey {
    font-family: 'Nunito', sans-serif;
    color: #999fa5;
    font-weight: 400;
    font-size: 0.9em;
    margin-top: 0.1em;
}
.fontSmallGrey {
    font-family: 'Nunito', sans-serif;
    color: #999fa5;
    font-weight: 400;
    font-size: 0.8em;
    margin-top: 0.1em;
}
.fontVerySmallGrey {
    font-family: 'Nunito', sans-serif;
    color: #999fa5;
    font-weight: 400;
    font-size: 0.6em;
    margin-top: 0.1em;
}
.fontSmallWhite {
    font-family: 'Nunito', sans-serif;
    color: #fff;
    font-weight: 400;
    font-size: 0.8em;
    margin-top: 0.1em;
}

.btnToggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #91c023;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
    margin: 2% 0 0 2%;
}
.trangleRight {
    border-top: 25px solid transparent;
    border-left: 20px solid #fff;
    border-bottom: 25px solid transparent;
    height: 0;
    opacity: 0.3;
}
.trangleLeft {
    border-top: 25px solid transparent;
    border-right: 20px solid #fff;
    border-bottom: 25px solid transparent;
    height: 0;
    opacity: 0.3;
}
.colorPickerTableau {
    display: block;
    div {
        display: block;
    }
}
/* --------------CSS input date semaine------------------ */

.dayIsBetween {
    background: #43a047;
    color: white;
}
.firstDay {
    border-top-left-radius: 50% !important;
    border-radius: 50%;
}
.endDay {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}
/*------------CSS topbar + menu espace responsable-------------------*/

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
    height: 60px;
    background-color: #242526;
    padding: 0 1rem;
    border-bottom: 1px solid #474a4d;
    display: flex;
    justify-content: space-between;
}

/* <ul> */
.navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    margin-left: 2%;
}

/* <li> */
.nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Icon Button */
.icon-button {
    --button-size: calc(60px * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: #484a4d;
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    color: #fff;
}
.text-button {
    color: #fff;
    padding: 5px;
}
.icon-button:hover {
    filter: brightness(1.2);
}

.icon-button svg {
    fill: #dadce1;
    width: 20px;
    height: 20px;
}

/* Dropdown Menu */

.dropdown {
    position: absolute;
    top: 58px;
    width: 300px;
    transform: translateX(+25%);
    background-color: #242526;
    border: 1px solid #474a4d;
    border-radius: 8px;
    padding: 1rem;
    overflow: hidden;
    transition: height 500ms ease;
    z-index: 10;
}

.menu {
    width: 100%;
}

.menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    transition: background 500ms;
    padding: 0.5rem;
    color: #fff;
}

.menu-item .icon-button {
    margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
    filter: none;
}

.menu-item:hover {
    background-color: #525357;
}

.icon-right {
    margin-left: auto;
}

.btn {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    /* padding-left: 5px; */
    padding-right: 5px;
    &:hover + .box {
        transform: scale(1);
        span {
            transform: translateY(0);
        }
    }
}
.box {
    transform: scale(1);
    transition: transform 1000ms ease-in-out;
    overflow: hidden;
    span {
        display: inline-block;
        transform: translateX(-250%);
        transition: transform 1000ms ease-out 200ms;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        padding-left: 5px;
        padding-right: 20px;
    }
}
.smallContainer {
    margin: 2.5% 5% 0 5% !important;
    background-color: #ffffff !important;
}
